/* vars */
/* Fonts */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiAyp8kv8JHgFVrJJLmE3tF.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmv1plEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm21llEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrJJLedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmg1hlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmr19lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmy15lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm111lEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm81xlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrLPTedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLFj_V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDD4V1s.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5V1s.ttf) format('truetype');
}
body {
  font-family: 'Poppins', sans-serif;
  color: #3C5063;
}
/* Global styles */
h1 {
  margin-top: 0px;
  font-size: 28px;
  font-weight: 600;
}
h2 {
  font-size: 15px;
  font-weight: 500;
}
body {
  margin: 0px;
}
body,
body #root {
  min-height: 100vh;
}
.separator {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0px;
  border-top: 1px solid #E4E4E4;
}
.content-wrap {
  display: flex;
  justify-content: center;
}
.content {
  width: 100%;
  max-width: 1375px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.instruction {
  font-style: italic;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.full-width-select-cont,
.full-width-select-cont .custom-select {
  min-width: 100%;
}
.flex-row-select-cont {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.flex-row-select-cont .custom-select {
  width: 100%;
  max-width: unset;
}
.flex-row-checkbox-cont {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.flex-row-checkbox-cont label {
  display: inline-block;
}
.flex-row-buttons-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
}
.button.button-loading {
  background: #E4E4E4 !important;
  cursor: not-allowed !important;
}
.button.button-loading:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #E4E4E4;
  background: #F6F8F9;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  color: #605795;
}
input:focus {
  outline: none;
  border: 1px solid #605795;
}
textarea {
  font-family: 'Roboto', sans-serif;
  width: 40%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #E4E4E4;
  background: #F6F8F9;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 15px;
  color: #605795;
}
button,
.mainButton {
  padding: 10px;
  margin: 10px 0;
  border: none;
  background: #605795;
  color: #FFFFFF;
  border-radius: 6px;
  font-size: 15px;
  cursor: pointer;
  transition-duration: 0.4s;
  width: 300px;
  border: 1px solid #605795;
  text-decoration: none;
}
button:hover,
.mainButton:hover {
  opacity: 0.9;
  cursor: pointer;
}
.pdfButton {
  display: inline-block;
  text-align: center;
  line-height: 1.2em;
  box-sizing: border-box;
}
button.success::after {
  content: '✔';
  color: #359E59;
  margin-left: 8px;
  font-size: 16px;
}
button.error::after {
  content: '✘';
  color: #c73939;
  margin-left: 8px;
  font-size: 16px;
}
button.grey-btn {
  color: #605795;
  border: 1px solid #E4E4E4;
  background: #F6F8F9;
}
.date-selector-cont {
  width: 100%;
  display: inline-block;
}
.react-datepicker-wrapper {
  width: 300px;
  margin-bottom: 10px;
}
.react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}
.text-button {
  background: none;
  border: none;
  color: #605795;
  padding: 0px;
  width: auto !important;
}
.text-button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.login-layout {
  min-height: 100vh;
  background: #605795;
  display: flex;
  flex-direction: row;
  position: relative;
}
.login-layout .left-cont {
  min-width: 50%;
  max-width: 50%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-layout .left-cont img {
  max-width: 60%;
  min-width: 60%;
}
.login-layout .right-cont {
  background: #FFFFFF;
  min-height: 100%;
  min-width: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  padding: 50px;
  box-sizing: border-box;
}
.portal-layout .header {
  background: #605795;
  height: 197px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.portal-layout .header .content-wrap {
  max-height: 100%;
}
.portal-layout .header .content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-height: 100%;
}
.portal-layout .header .header-logo {
  max-height: 100%;
  width: auto;
}
.portal-layout .main {
  background: #F6F8F9;
  min-height: calc(100vh - 197px);
  position: relative;
}
.portal-layout .main .content-wrap,
.portal-layout .main .content {
  height: 100%;
  min-height: 100%;
}
.portal-layout .main .content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 50px;
}
.portal-layout .main .main-page {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  padding: 30px 50px;
}
.login-layout h1 {
  margin-bottom: 10px;
}
.login-layout h2 {
  margin-top: 0px;
  margin-bottom: 10px;
}
.update-student-progress .student-details-cont {
  position: relative;
}
.progress-chart-outer-cont {
  padding-top: 50px;
  padding-bottom: 30px;
}
.group-reports .select-report-filters-cont {
  position: relative;
}
.group-reports .select-report-filters-cont .clear-filters-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}
.group-reports .select-report-filters-cont .clear-filters-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}
.group-reports .select-report-filters-cont .generate-report-validation-msg {
  margin-left: 20px;
  color: #c73939;
}
.login-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.login-form #login-message {
  color: #c73939;
  min-height: 40px;
}
.login-form button.loading {
  opacity: 0.6;
  cursor: not-allowed;
}
.sidebar {
  min-width: 300px;
  max-width: 300px;
  padding-right: 30px;
  box-sizing: border-box;
}
.sidebar .collapsible-cont {
  margin-bottom: 30px;
}
.sidebar .collapsible-content-inner {
  padding-left: 32px;
}
.sidebar a {
  text-decoration: none;
  color: #3C5063;
  font-size: 15px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
  display: inline-block;
  min-width: 100%;
}
.header .user {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.header .user .userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #E8896D;
  background: #F6F8F9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .user .userAvatar span {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  color: #E8896D;
}
.header .user span {
  color: #E8896D;
  font-size: 18px;
}
.header .user .log-out {
  color: #FFFFFF;
  transition-duration: 0.4s;
}
.header .user .log-out:hover {
  cursor: pointer;
  opacity: 0.9;
}
.select-student-cont {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.select-student-cont .custom-select {
  width: 270px;
}
.bundle-progress-cont {
  position: relative;
}
.bundle-progress-cont .level-cont-outer {
  margin-bottom: 38px;
}
.bundle-progress-cont .level-cont {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  background: #F6F8F9;
  padding: 25px;
}
.bundle-progress-cont .bundle {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #605795;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.1s ease-in-out;
}
.bundle-progress-cont .bundle p {
  color: #605795;
  font-size: 12px;
  line-height: 1.3em;
}
.bundle-progress-cont .bundle:hover {
  opacity: 0.8;
  cursor: pointer;
}
.bundle-progress-cont .bundle.completed {
  background: #605795;
  position: relative;
}
.bundle-progress-cont .bundle.completed p {
  color: #FFFFFF;
}
.bundle-progress-cont .bundle.completed:after {
  content: 'Complete';
  position: absolute;
  top: -8px;
  right: -8px;
  background: #359E59;
  border-radius: 6px;
  color: white;
  font-size: 8px;
  line-height: 1.2em;
  padding: 3px 4px;
}
.bundle-progress-cont .bundle.in-progress {
  background: #605795;
  position: relative;
}
.bundle-progress-cont .bundle.in-progress p {
  color: #FFFFFF;
}
.bundle-progress-cont .bundle.in-progress:after {
  content: 'In progress';
  position: absolute;
  top: -8px;
  right: -8px;
  background: #E8896D;
  border-radius: 6px;
  color: white;
  font-size: 8px;
  line-height: 1.2em;
  padding: 3px 4px;
}
.progress-table {
  background: #F6F8F9;
  padding: 30px;
  text-align: left;
  font-size: 15px !important;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
}
.progress-table th {
  padding-bottom: 12px;
  color: #605795;
}
.progress-table td {
  text-wrap: nowrap;
  padding-right: 10px;
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level {
  position: relative;
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:before {
  position: absolute;
  top: -24px;
  color: #3C5063;
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(1):before {
  content: '1';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(2):before {
  content: '2';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(3):before {
  content: '3';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(4):before {
  content: '4';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(5):before {
  content: '5';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(6):before {
  content: '6';
}
.progress-table table tbody tr:nth-of-type(1) table tr:nth-of-type(1) .level-display-cont .level:nth-of-type(7):before {
  content: 'Total';
}
.progress-table .averages-table {
  width: 100%;
  min-width: 100%;
}
.progress-table .averages-table .level-display-cont {
  display: flex;
  justify-content: flex-end;
}
.progress-table .averages-table .level-display-cont .average {
  background: #605795;
  color: white;
}
.progress-table .numbers-average-note {
  position: absolute;
  top: -33px;
  font-size: 10px;
  max-width: 90px;
  text-wrap: wrap;
  text-align: center;
  font-style: italic;
}
.level-display-cont {
  display: flex;
  gap: 8px;
}
.level-display-cont .level {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  min-width: 44px;
  max-width: 44px;
  min-height: 23px;
  max-height: 23px;
  text-align: center;
  font-size: 10px;
}
.level-display-cont .level.half-complete {
  background: #EFA12C;
  color: white;
}
.level-display-cont .level.complete {
  background: #359E59;
  color: white;
}
.level-display-cont .level.total {
  margin-left: 15px;
}
.level-display-cont .level.total.half-complete,
.level-display-cont .level.total.complete {
  background: #605795;
}
.level-display-cont .level.disabled {
  background: #B3B3B3 !important;
  color: #B3B3B3 !important;
}
.level-display-cont .level.disabled:hover {
  cursor: not-allowed !important;
  opacity: 1 !important;
}
.loading.baseline-assessment-form {
  min-height: 740px;
  background: #F6F8F9;
  padding: 30px;
  text-align: left;
  font-size: 15px !important;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
}
.crud-list {
  width: 100%;
  /* Adjust width as needed */
  margin-bottom: 20px;
  /* Adjust margin as needed */
}
.crud-list .custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}
.crud-list .custom-table .separator-row {
  display: inline-block;
}
.crud-list .custom-table th {
  background-color: #605795;
  color: #FFFFFF;
  text-align: left;
  padding: 10px;
  text-transform: capitalize;
}
.crud-list .custom-table th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.crud-list .custom-table th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.crud-list .custom-table td {
  padding: 5px 10px;
  border: none;
}
.crud-list .custom-table td:last-child {
  border-bottom: none;
}
.crud-list .custom-table td.edit-dots {
  text-align: start;
}
.crud-list .custom-table tbody button {
  width: 20px;
  background: none;
  border: none;
  margin: 0;
}
.crud-list .custom-table tbody tr td {
  line-height: 1em;
}
.crud-list .custom-table tbody tr td:first-child {
  border-left: 1px solid #E4E4E4;
}
.crud-list .custom-table tbody tr td:last-child {
  border-right: 1px solid #E4E4E4;
}
.crud-list .custom-table tbody tr:first-of-type td {
  border-top: 1px solid #E4E4E4;
}
.crud-list .custom-table tbody tr:first-of-type td:first-child {
  border-left: 1px solid #E4E4E4;
  border-top-left-radius: 5px;
}
.crud-list .custom-table tbody tr:first-of-type td:last-child {
  border-right: 1px solid #E4E4E4;
  border-top-right-radius: 5px;
}
.crud-list .custom-table tbody tr:last-of-type td {
  border-bottom: 1px solid #E4E4E4;
}
.crud-list .custom-table tbody tr:last-of-type td:first-child {
  border-left: 1px solid #E4E4E4;
  border-bottom-left-radius: 5px;
}
.crud-list .custom-table tbody tr:last-of-type td:last-child {
  border-right: 1px solid #E4E4E4;
  border-bottom-right-radius: 5px;
}
.crud-list .custom-table tbody tr:nth-child(even) td {
  background-color: #F6F8F9;
}
.pagination-count {
  margin-bottom: 20px;
  text-align: left;
}
.crud-edit .edit-heading {
  display: flex;
  justify-content: space-between;
}
.single-edit .bin-icon {
  position: absolute;
  top: 20px;
  right: 0;
  cursor: pointer;
}
.single-edit .single-inner-cont {
  position: relative;
}
.single-edit .single-inner-cont .repeater-cont {
  width: 100%;
  margin-top: 15px;
}
.single-edit .single-inner-cont .repeater-cont table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px;
}
.single-edit .single-inner-cont .repeater-cont table th {
  min-width: 100%;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
}
.single-edit .single-inner-cont .repeater-cont table .contacts-bin-icon {
  cursor: pointer;
  margin-top: 15px;
}
.single-edit .single-inner-cont .repeater-cont table th,
.single-edit .single-inner-cont .repeater-cont table td {
  text-align: left;
  vertical-align: top;
}
.single-edit .single-inner-cont .repeater-cont table th input,
.single-edit .single-inner-cont .repeater-cont table td input {
  width: 100%;
}
.single-edit .single-inner-cont .repeater-cont table .react-datepicker-wrapper {
  width: 100%;
}
.single-edit .single-inner-cont .repeater-cont td.period-name {
  width: 100%;
  display: block;
}
.single-edit .single-inner-cont .react-datepicker-popper {
  z-index: 99;
}
.single-edit .field-instruction {
  min-width: 100%;
  display: inline-block;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
}
.single-edit .error-messages {
  width: 100%;
  background: #ffeeee;
  border: 1px solid #c73939;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
}
.single-edit .error-messages p {
  color: #c73939;
  line-height: 1em;
  margin-top: 3px;
  margin-bottom: 3px;
}
.single-edit form label {
  display: block;
}
.single-edit form input {
  width: 300px;
}
.crud-page,
.manage-students {
  position: relative;
}
.crud-page .add-new,
.manage-students .add-new {
  position: absolute;
  top: 0px;
  right: 0px;
}
.crud-page .flex-row-select-cont,
.manage-students .flex-row-select-cont {
  margin-top: 50px;
}
.crud-page .clear-filters-btn,
.manage-students .clear-filters-btn {
  position: absolute;
  top: 65px;
  right: 5px;
}
.crud-page .clear-filters-btn:hover,
.manage-students .clear-filters-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}
.crud-page .password-reset-btn,
.manage-students .password-reset-btn {
  display: block;
  position: relative;
  color: #605795;
  text-decoration: underline;
}
.crud-page .password-reset-btn:hover,
.manage-students .password-reset-btn:hover {
  cursor: pointer;
  opacity: 0.8;
}
.level-display-cont.editable .level:hover {
  opacity: 0.8;
  cursor: pointer;
}
.notes .notes-outer-cont {
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  background: #F6F8F9;
  padding: 25px;
}
.notes .notes-outer-cont .note-table-cont {
  min-height: 350px;
  max-height: 350px;
  overflow-y: scroll;
}
.notes .notes-outer-cont .headings .notes-table .name,
.notes .notes-outer-cont .headings .notes-table .date,
.notes .notes-outer-cont .headings .notes-table .note-content,
.notes .notes-outer-cont .headings .notes-table .next-steps-content,
.notes .notes-outer-cont .headings .notes-table .follow-up-content {
  color: #605795;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.1em;
}
.notes .notes-outer-cont .notes-table {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}
.notes .notes-outer-cont .notes-table p {
  font-size: 12px;
  line-height: 1.3em;
}
.notes .notes-outer-cont .notes-table .name {
  width: 12%;
}
.notes .notes-outer-cont .notes-table .date {
  width: 10%;
}
.notes .notes-outer-cont .notes-table .note-content {
  width: 22%;
}
.notes .notes-outer-cont .notes-table .next-steps-content {
  width: 22%;
}
.notes .notes-outer-cont .notes-table .follow-up-content {
  width: 22%;
}
.notes .notes-outer-cont .notes-table .notes-cont {
  width: 70%;
  border-collapse: collapse;
}
.notes .notes-outer-cont .notes-table .notes-cont textarea {
  font-size: 12px;
  width: 100%;
  height: 50px;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  border: none;
  border-bottom: 1px solid #E4E4E4;
  border-radius: 0;
}
.notes .notes-outer-cont .notes-table .notes-cont td {
  font-size: 12px;
  padding-top: 12px;
}
.notes .notes-outer-cont .notes-table .notes-cont td:first-child {
  width: 10%;
  font-weight: bold;
  vertical-align: top;
}
.notes .notes-outer-cont .notes-table .notes-cont td:nth-child(2) {
  width: 70%;
  vertical-align: top;
}
.notes .notes-outer-cont .notes-table .notes-cont td:last-child {
  width: 10%;
  text-align: right;
  vertical-align: bottom;
}
.notes .notes-outer-cont .notes-table .notes-cont .update-notes-button {
  margin-bottom: 4px;
  margin-top: 0px;
  text-wrap: nowrap;
}
.notes .notes-outer-cont .add-note-cont {
  background: white;
  border: 1px solid #E4E4E4;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 20px;
  align-items: flex-end;
}
.notes .notes-outer-cont .add-note-cont .username {
  width: 10%;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
  margin-bottom: 16px;
}
.notes .notes-outer-cont .add-note-cont form {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: end;
  width: 88%;
}
.notes .notes-outer-cont .add-note-cont form .note-input label {
  min-width: 100%;
  display: inline-block;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
}
.notes .notes-outer-cont .add-note-cont form .note-input #note-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #E4E4E4;
  font-size: 12px;
  width: 100%;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-family: 'Poppins', sans-serif;
}
.notes .notes-outer-cont .add-note-cont form .next-steps-input label {
  min-width: 100%;
  display: inline-block;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
}
.notes .notes-outer-cont .add-note-cont form .next-steps-input #next-steps-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #E4E4E4;
  width: 100%;
  font-size: 12px;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-family: 'Poppins', sans-serif;
}
.notes .notes-outer-cont .add-note-cont form .follow-up-input label {
  min-width: 100%;
  display: inline-block;
  color: #3C5063;
  opacity: 0.5;
  font-size: 10px;
  line-height: 1.1em;
  font-style: italic;
}
.notes .notes-outer-cont .add-note-cont form .follow-up-input #follow-up-input {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #E4E4E4;
  width: 100%;
  font-size: 12px;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-family: 'Poppins', sans-serif;
}
.notes .notes-outer-cont .add-note-cont form .add-notes-btn {
  width: 20%;
  margin-bottom: 16px;
}
.collapsible-header {
  display: flex;
  justify-content: space-between;
}
.collapsible-header h3 {
  margin: 0px;
}
.collapsible-header:hover {
  cursor: pointer;
}
.collapsible-content {
  overflow: hidden;
  transition-duration: 0.5s;
}
.collapsible-content-open {
  margin-top: 30px;
}
.collapsible-content-inner ul {
  margin: 0px;
}
.custom-checkbox {
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
}
.custom-checkbox input {
  opacity: 0;
  position: absolute;
  left: 0px;
  min-width: 28px;
  min-height: 28px;
}
.custom-checkbox input:hover {
  cursor: pointer;
}
.custom-checkbox label {
  padding-left: 40px;
  transition-duration: 0.1s;
  box-sizing: border-box;
}
.custom-checkbox label:hover {
  cursor: pointer;
  opacity: 0.8;
}
.custom-checkbox:before {
  content: '';
  min-width: 28px;
  min-height: 28px;
  background: #F6F8F9;
  border: 1px solid #E4E4E4;
  border-radius: 6px;
  position: absolute;
  left: 0px;
}
.custom-checkbox.checked:before {
  content: '';
  background: #F6F8F9 url('../assets/check.svg') no-repeat center center;
}
.custom-select {
  max-width: 300px;
  position: relative;
}
.custom-select .custom-select-input {
  position: relative;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #E4E4E4;
  background: #F6F8F9;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 15px;
  color: #605795;
  z-index: 3;
}
.custom-select .custom-select-input:hover {
  cursor: pointer;
}
.custom-select .custom-select-input:after {
  content: url('../assets/chevron.svg');
  transition-duration: 0.1s;
  position: absolute;
  right: 8px;
  top: 12px;
}
.custom-select .custom-select-options {
  position: absolute;
  width: 100%;
  top: 40px;
  left: 0px;
  max-height: 400px;
  height: 0px !important;
  overflow: hidden;
  background: #F6F8F9;
  border: 1px solid #E4E4E4;
  box-sizing: border-box;
  border-radius: 6px;
  z-index: 2;
  transition-duration: 0.3s;
}
.custom-select .custom-select-options .custom-select-options-inner {
  margin-top: 5px;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
}
.custom-select .custom-select-options .custom-select-option:first-child {
  margin-top: 10px;
}
.custom-select .custom-select-options .custom-select-option {
  padding: 10px;
  opacity: 0.8;
  transition-duration: 0.1s;
  color: #605795;
  font-size: 15px;
}
.custom-select .custom-select-options .custom-select-option:hover {
  opacity: 1;
  cursor: pointer;
  color: #E8896D;
}
.custom-select.open {
  z-index: 5;
}
.custom-select.open .custom-select-options {
  height: 400px !important;
}
.custom-select.open .custom-select-input:after {
  transform: rotate(180deg);
  top: 6px;
}
.custom-select.closing {
  z-index: 5;
}
